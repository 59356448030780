<template>
  <v-btn
    class="btn-pill font-weight-bold px-2"
    :class="[textColor, `outline-${outlineColor}`]"
    depressed
    rounded
    min-width="fit-content"
    :color="bgColor"
    :width="width"
    :max-width="maxWidth"
    :disabled="disabled"
    @click="$emit('clickBtn')"
  >
    {{ noI18n ? btnText : $t(btnText) }}
  </v-btn>
</template>

<script>
export default {
  props: {
    btnText: {
      type: String,
      default: ''
    },
    noI18n: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '100%'
    },
    maxWidth: {
      type: String,
      default: ''
    },
    textColor: {
      type: String,
      default: 'lightPrimary2--text'
    },
    bgColor: {
      type: String,
      default: 'primary'
    },
    outlineColor: {
      type: String,
      default: 'lightPrimary2'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.btn-pill {
  outline: 2px solid $color-lightPrimary2;
  &.outline-primary {
    outline: 2px solid var(--v-primary-base);
  }
}
</style>
