export default {
  // langs
  tw: '繁體中文',
  jp: '日本語',
  en: 'English',
  cn: '简体中文',
  vi: 'Tiếng Việt',
  // home
  login: 'Đăng nhập',
  logout: 'Đăng xuất',
  indexSubtitle: 'OinETH lưu trữ tài sản mã hóa của bạn thông qua hợp đồng thông minh với lợi nhuận ổn định',
  indexTitle: 'Stake kiếm lợi nhuận chỉ đơn giản như thế',
  depositImmediately: 'Stake ngay',
  totalDepositEth: 'Tổng số ETH đã đầu tư',
  // footer
  footerDeposit: 'Tôi muốn stake',
  footerSwap: 'Đổi tiền tệ',
  footerRule: 'Quy tắc kiếm lãi',
  footerAsset: 'Tài sản của tôi',
  // swap
  tokenSwap: 'Đổi tiền tệ',
  swapRegisterTitle: 'Gửi coin sinh coin, chỉ đơn giản như thế',
  swapRegisterSubtitle: 'Đăng ký ngay để kích hoạt ví tiền khai thác blockchain của bạn',
  enterSwapRegisterAddress: 'Hãy điền địa chỉ ví tiền giao dịch lần này',
  enterAddress: 'Hãy nhập địa chỉ ví tiền',
  confirm: 'Xác nhận',
  back: 'Quay về',
  // rule
  ruleWarning: 'Phí khai thác BNB sau đây phải do nhà đầu tư tự chịu trách nhiệm',
  ruleContent: [
    'Trước khi đầu tư, bạn phải lưu trữ loại tiền trong ví tiền điện tử trên blockchain và chọn và đăng ký stake trong \'Danh sách stake\'.',
    'Sau khi stake thành công, coin sẽ tự động được lưu trữ trong hợp đồng thông minh và bạn sẽ thấy hợp đồng lưu trữ coin đó trong \'Tài sản của tôi\'.',
    'Hợp đồng lưu trữ coin đó có thể rút vốn bất cứ lúc nào. Stake đủ một tháng, mỗi tháng sẽ được tính lợi tức, số tháng stake càng lâu thì tỉ lệ lợi tức càng tăng cao. Nếu stake đủ 12 tháng, lãi suất sẽ không được tính tiếp, bạn phải nhớ rút cả gốc và lãi, rồi thực hiện stake mới sau đó.',
    'Số tiền tối thiểu để stake là 1 EGT. Sau khi đầu tư lại/ rút về, hợp đồng thông minh sẽ chuyển vào ví điện tử của người dùng.',
    '"Lãi suất là lãi suất dự kiến biến động, sẽ được hiển thị trong hệ thống và chúng tôi có quyền quyết định cuối cùng"'
  ],
  // deposit
  depositAmount: 'Số lượng stake',
  enterDepositTokenAmount: 'Nhập số lượng {token}',
  max: 'Lớn nhất',
  tokenAvailable: 'Số lượng khả dụng {amount} {token}',
  detailedInfo: 'Thông tin chi tiết',
  profitDescription: 'Lợi nhuận từ hợp đồng stake này là {token}',
  depositDate: 'Ngày stake',
  startInterestDate: 'Ngày sinh lãi',
  distributeInterestDate: 'Ngày phát lãi',
  monthExpectedRate: 'Tỷ lệ dự kiến lãi suất hàng tháng',
  showMore: 'Hiến thị thêm',
  showLess: 'Rút gọn',
  firstMonth: 'Tháng đầu',
  nthMonth: 'Tháng thứ {month}',
  agreeRule: 'Tôi đã đọc hiểu và đồng ý Thoả thuận dịch vụ kiểm lãi',
  depositDescription: 'Linh hoạt mọi lúc, cho phép bạn đặt cược lại đồng mã hóa của bạn bất cứ lúc nào',
  // assets
  orderId: 'Mã số: {id}',
  orderTime: 'Thời gian stake {date} ngày',
  orderInterest: 'Lãi suất khả nhận: {amount} USDT',
  orderRate: 'Tỷ giá hiện tại {depositToken}={value} {interestToken}',
  redeemDepositToken: 'Rút vốn lẫn lãi',
  redeemDescription: 'Kết thúc hợp đồng stake này và rút tiền về',
  redeem: 'Xác nhận rút',
  claimInterest: 'Rút lãi suất',
  claimDescription: 'Rút lãi suất tích từ trước trong hợp đồng lãi suất stake',
  claim: 'Xác nhận',
  claimableTotalInterest: 'Lãi suất có thể rút',
  referenceTotalInterest: 'Lãi suất từ giới thiệu',
  claimedTotalInterest: 'Tất cả lãi suất đã rút',
  assetsSummary: 'Tổng quan',
  // other
  yourAddress: 'Địa chỉ nhận thanh toán của bạn',
  backToIndex: 'Quay về trang chủ',
  backToPreviousPage: 'Trở về',
  lock: 'Chưa kích hoạt',
  unlock: 'Đã kích hoạt',
  approve: 'Vui lòng mở khóa trước khi đăng ký',
  noRecord: 'Không có hồ sơ liên quan',
  activateService: 'Kích hoạt dịch vụ của bạn',
  fillActivateAddress: 'Vui lòng điền địa chỉ kích hoạt',
  currLoginAddress: 'Địa chỉ đăng nhập hiện tại',
  autoFill: 'Tự động điền',
  activateConfirm: 'Xác nhận kích hoạt',
  // toasted
  loginFirst: 'Vui lòng đăng nhập trước',
  installMetamask: 'Vui lòng cài đặt MetaMask',
  changeMainnet: 'Vui lòng chuyển sang chuỗi thông minh Binance',
  changeETH: 'Vui lòng chuyển sang mạng chính Ethereum',
  connectionBreak: 'Kết nối MetaMask bị gián đoạn, vui lòng đăng nhập lại',
  changeWallet: 'Đã chuyển đổi kết nối ví',
  waitLoading: 'Đang tải. Hãy đợi chút!',
  waitApprove: 'Đang mở khóa, vui lòng đợi một chút',
  waitActivate: 'Đang kích hoạt. Hãy đợi chút!',
  waitClaim: 'Đang rút. Hãy đợi chút!',
  txSend: 'Giao dịch đã được gửi đi',
  userRefuse: 'Người dùng từ chối kết nối',
  addressHasUsed: 'Địa chỉ này đã được đăng ký',
  errorOccured: 'Đã xảy ra lỗi',
  USDTUnderBalance: 'Số dư USDT không đủ',
  TBTUnderBalance: 'Số dư TBT không đủ',
  underBalance: 'Số dư không đủ',
  cannotGetGas: 'Không thể tính phí giao dịch',
  renewGetGas: 'Kết nối WebSocket bị gián đoạn, vui lòng làm mới trang web để nhận phí giao dịch ước tính mới nhất.',
  renew: 'Kết nối WebSocket bị gián đoạn, vui lòng làm mới trang web',
  waitGetData: 'Đang lấy thông tin từ chuỗi, vui lòng đợi một chút',
  noReward: 'Không có lợi nhuận nào có thể rút được',
  cannotGetEthValue: 'Không thể lấy được giá trị thị trường ETH',
  cannotGetData: 'Lấy dữ liệu thất bại',
  selectTx: 'Vui lòng chọn giao dịch cần hỗ trợ',
  txError: 'Giao dịch gặp sự cố',
  agreeFirst: 'Vui lòng đánh dấu đồng ý',
  cannotGetMarketValue: 'Không thể tính giá trị thị trường',
  isNotWhitelist: 'Không đủ quyền hạn. Hãy liên hệ với quản trị viên',
  notForTaiwan: 'Trang web này\n không phục vụ khách hàng khu vực Đài Loan',
  toDetails: 'Chuyển đến hướng dẫn chi tiết',
  close: 'Đóng',
  notForTaiwanContent: `"Công ty chúng tôi luôn nỗ lực cung cấp các sản phẩm và dịch vụ chất lượng cao, đồng thời xây dựng được mạng lưới khách hàng rộng khắp trên toàn cầu.\n\nTuy nhiên, do một số hạn chế về pháp lý và quản lý, công ty hiện tại không thể cung cấp sản phẩm và dịch vụ cho khách hàng ở khu vực Đài Loan.\n\nChúng tôi thực sự rất tiếc về quyết định này làm ảnh hưởng đến quý khách. Cảm ơn sự ủng hộ và niềm tin của quý khách dành cho chúng tôi và cảm ơn sự thấu hiểu của quý khách.\n\nNếu quý khách có bất kỳ câu hỏi hoặc cần quyết định thêm. Vui lòng liên hệ với bộ phận chăm sóc khách hàng của chúng tôi. Chúng tôi sẽ nỗ lực hết sức để hỗ trợ và giúp đỡ quý khách.\n\nMột lần nữa, chúng tôi xin cảm ơn sự ủng hộ và thấu hiểu của quý khách. Chúng tôi rất hy vọng có cơ hội được phục vụ quý khách."`,
  waitForChainData: `Chờ dữ liệu được lấy trên chuỗi khối`,
  depositNowIfHavent: `Nếu chưa gửi coin. Vui lòng nhấp vào Stake Ngay`
}